import React, { useState } from "react"
import { Link } from "react-router-dom"
import { DateDifferenceInWords, SmartDateFormat } from "../../util/dates"
import { PauseCircle, PlayCircle, PlusCircle } from "react-bootstrap-icons"
import { Spinner } from "../shared/Spinner"
import { RequestError } from "../shared/RequestError"
import { compareByKey } from "../../util/utils"
import CommentScreen from "../comments/CommentScreen"
import { useDispatch, useSelector } from "react-redux"
import {
  prependAndPlay,
  pushToQueue,
  playOrPause,
} from "../../data/mediaPlayerSlice"
import { SingleTrackItem } from "./SingleTrackItem"
import { PostListItemExpandedStyles } from "./constants"
import { usePost } from "../../hooks/usePost"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { AnchorButton, ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import PremiereHeader from "./PremiereHeader"
import clsx from "clsx"
import styles from "./styles.module.css"

export const AlbumListItemExpanded = ({
  album,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(album.status)
  const albumDetailData = usePost(album.id, "songs")
  var albumDetails = albumDetailData?.data?.post

  const dispatch = useDispatch()
  const currentMediaItem = useSelector(
    // @ts-ignore
    (state) => state.mediaPlayer.value.currentMediaItem,
  )

  // @ts-ignore
  const playerState = useSelector(
    (state) => state.mediaPlayer.value.playerState,
  )
  const isCurrentlyPlaying =
    playerState === "playing" && currentMediaItem.albumId === album.id
  const isCurrentlyQueued =
    playerState === "paused" && currentMediaItem.albumId === album.id

  const onPlayClick = (e) => {
    dispatch(prependAndPlay(sortedSongs()))
  }
  const onPlayOrPauseClick = (e) => {
    dispatch(playOrPause())
  }

  const onAddClick = (e) => {
    dispatch(pushToQueue(sortedSongs()))
  }

  const sortedSongs = () => {
    return [...albumDetails.songs]
      .sort((a, b) => compareByKey(a, b, "track_number"))
      .map((song) => ({
        ...song,
        album_name: albumDetails.title,
        cover_image_small: albumDetails.cover_image_small,
      }))
  }

  return (
    <div className="grid grid-cols-12">
      <PremiereHeader post={album} />
      <div className={PostListItemExpandedStyles.contentDiv}>
        <img
          src={album.external_photo_src}
          alt={album.title}
          className="w-full cursor-pointer"
          style={{ aspectRatio: "1/1" }}
          onClick={(e) => setIsExpanded(false)}
        />
      </div>
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex">
          <div className="flex flex-none w-12">
            {isCurrentlyPlaying ? (
              <PauseCircle
                className="mt-1 mr-1 inline cursor-pointer"
                size="16"
                onClick={onPlayOrPauseClick}
              />
            ) : (
              <PlayCircle
                className="mt-1 mr-1 inline cursor-pointer"
                size="16"
                onClick={isCurrentlyQueued ? onPlayOrPauseClick : onPlayClick}
              />
            )}
            <PlusCircle
              className="mt-1 mr-1 inline cursor-pointer"
              size="16"
              onClick={onAddClick}
            />
          </div>

          <div className="flex-grow">
            <h2
              className={clsx(
                PostListItemExpandedStyles.header,
                "cursor-pointer inline mr-2",
                album.is_bn_premiere && styles.premiereTitle,
              )}
              dangerouslySetInnerHTML={{ __html: album.title }}
              onClick={(e) => setIsExpanded(false)}
            ></h2>
            <CopyPostUrlLink nadaUrl={album.nada_url} renderMode="button" />
          </div>
        </div>
        <h3>
          <Link
            className={PostListItemExpandedStyles.bandName}
            to={album.local_path}
          >
            {album.band_name}
          </Link>
        </h3>
        <div className="text-xs">
          {album.future_active_date &&
          DateDifferenceInWords(
            new Date(album.future_active_date),
            new Date(album.active_date),
          ).diffInSeconds > 86400 ? (
            <div>
              Announced&nbsp;
              {SmartDateFormat({
                dateStr: album.active_date,
                includeTime: false,
              })}
              &nbsp;for Release on&nbsp;
              {SmartDateFormat({
                dateStr: album.future_active_date,
                includeTime: false,
              })}
            </div>
          ) : (
            <div>
              Released&nbsp;
              {SmartDateFormat({
                dateStr: album.active_date,
                includeTime: false,
              })}
            </div>
          )}
        </div>
        <div className={PostListItemExpandedStyles.externalLink}>
          {album.class_name === "Bandcamp" ? (
            <div className="mt-1">
              <AnchorButton href={album.external_url} target="_blank">
                Buy on&nbsp;
                {album.class_name}
              </AnchorButton>
            </div>
          ) : (
            <a href={album.external_url} target="_blank" rel="noreferrer">
              view on&nbsp;
              {album.class_name}
            </a>
          )}
        </div>

        <div className="mt-4">
          <RelatedPosts postId={album.id} />
        </div>

        <div className="mt-4">
          <ReactionBlock
            contentId={album.id}
            contentType="Post"
            postId={album.id}
          />
        </div>
        <div className="mt-2">
          {albumDetailData.status === "loading" ? (
            <Spinner className="text-gray-500" />
          ) : albumDetailData.status === "error" ? (
            <RequestError error={albumDetailData.error} />
          ) : (
            <>
              {albumDetails.songs?.length > 0 ? (
                <>
                  <ul className="text-sm text-black">
                    {sortedSongs().map((song, index) => (
                      <SingleTrackItem
                        key={song.id}
                        song={song}
                        index={index}
                      />
                    ))}
                  </ul>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <CommentScreen
          postId={album.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}
