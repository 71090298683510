import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import FreeTextFormat from "../shared/FreeTextFormat"
import CommentScreen from "../comments/CommentScreen"
import { PostListItemExpandedStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import { transformCloudUrl } from "../../util/strings"
import { Player, ControlBar } from "video-react"
import "/node_modules/video-react/dist/video-react.css"
import PremiereHeader from "./PremiereHeader"
import clsx from "clsx"
import styles from "./styles.module.css"

export const VideoListItemExpanded = ({
  video,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(video.status)

  return (
    <div className="grid grid-cols-12">
      <PremiereHeader post={video} />
      <div className={PostListItemExpandedStyles.contentDiv}>
        {video.post_type === "link_video" ? (
          <>
            <Player autoPlay src={transformCloudUrl(video.external_url)}>
              <ControlBar autoHide={true} />
            </Player>
          </>
        ) : (
          <iframe
            src={video.embed_url}
            width="100%"
            height="auto"
            title={video.title}
            allow="fullscreen"
            style={{ aspectRatio: "16/9" }}
          />
        )}
      </div>
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex">
          <div className="flex-grow">
            <h2
              className={clsx(
                PostListItemExpandedStyles.header,
                "cursor-pointer inline mr-2",
                video.is_bn_premiere && styles.premiereTitle,
              )}
              dangerouslySetInnerHTML={{ __html: video.title }}
              onClick={(e) => setIsExpanded(false)}
            ></h2>
            <CopyPostUrlLink nadaUrl={video.nada_url} renderMode="button" />
          </div>
        </div>
        <Link
          className={PostListItemExpandedStyles.bandName}
          to={video.local_path}
        >
          {video.band_name}
        </Link>
        <h3 className={PostListItemExpandedStyles.postDate}>
          Released:&nbsp;
          {SmartDateFormat({ dateStr: video.active_date, includeTime: false })}
        </h3>
        <div className={PostListItemExpandedStyles.externalLink}>
          <a href={video.external_url} target="_blank" rel="noreferrer">
            view on&nbsp;
            {video.view_on}
          </a>
        </div>
        <div className="mt-4">
          <RelatedPosts postId={video.id} />
        </div>
        <div className="mt-4">
          <ReactionBlock
            contentType="Post"
            contentId={video.id}
            postId={video.id}
          />
        </div>

        <div className={PostListItemExpandedStyles.description}>
          <FreeTextFormat text={video.description} expandable={false} />
        </div>
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <CommentScreen
          postId={video.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}
