import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import CommentScreen from "../comments/CommentScreen"
import { PostListItemStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { LinkPreview } from "./LinkPreview"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import LinkifyText from "../shared/LinkifyText"
import RelatedPosts from "./RelatedPosts"
import { UserContext } from "../HomeApp"
import PostEditButton from "../PostEditButton"
import HeadlinePostForm from "../posts/HeadlinePostForm"
import clsx from "clsx"
import styles from "./styles.module.css"
import PremiereHeader from "./PremiereHeader"

export const LinkListItem = ({
  defaultPost,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const { user } = useContext(UserContext)
  const [post, setPost] = useState(defaultPost)
  const [postStatus, setPostStatus] = useState(post.status)
  const linkTitle =
    post.post_type === "link"
      ? `New link shared by ${post.band_name}`
      : `New image shared by ${post.band_name}`

  return showEditForm ? (
    <HeadlinePostForm
      setNewPost={setPost}
      bandId={user.band_id}
      setShowForm={setShowEditForm}
      post={post}
    />
  ) : (
    <div className="grid grid-cols-12">
      <LinkPreview
        defaultImage={post.external_photo_src || post.cover_photo_url_medium}
        unfurledObj={post.link_preview}
        onClick={(e) => setIsExpanded(true)}
      />
      <div className={PostListItemStyles.metaDiv}>
        <div className="flex items-start">
          <h2
            className={clsx(
              PostListItemStyles.header,
              "cursor-pointer",
              post.is_bn_premiere && styles.premiereTitle,
            )}
            onClick={(e) => setIsExpanded(true)}
            style={{ lineHeight: "1.3rem" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: LinkifyText(post.title),
              }}
            ></span>
          </h2>
          <CopyPostUrlLink nadaUrl={post.nada_url} renderMode="icon" />
          <PostEditButton post={post} setShowEditForm={setShowEditForm} />
        </div>

        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <Link className={PostListItemStyles.bandName} to={post.local_path}>
          {post.band_name}
        </Link>
        <h3 className={PostListItemStyles.postDate}>
          Posted:&nbsp;
          {SmartDateFormat({
            dateStr: post.active_date,
            includeTime: false,
          })}
        </h3>
        <div className={PostListItemStyles.externalLink}>
          <a href={post.external_url} target="_blank" rel="noreferrer">
            view on&nbsp;
            {post.view_on}
          </a>
        </div>
        <div className={PostListItemStyles.description}>
          {post.link_preview.best_title}
        </div>
        <div className="mt-4">
          <RelatedPosts postId={post.id} />
        </div>
        <div className="mt-6">
          <ReactionBlock
            contentType="Post"
            contentId={post.id}
            postId={post.id}
          />
        </div>

        <CommentScreen postId={post.id} />
      </div>
    </div>
  )
}
