import React from "react"
import styles from "./styles.module.css"
import clsx from "clsx"

const PremiereHeader = ({ post }) => {
  return (
    post.is_bn_premiere && (
      <div className={clsx(styles.premiereHeader, "col-span-12")}>
        bandNada Premiere
      </div>
    )
  )
}

export default PremiereHeader
