import React, { useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import CommentScreen from "../comments/CommentScreen"
import FreeTextFormat from "../shared/FreeTextFormat"
import { PostListItemExpandedStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { LinkPreview } from "./LinkPreview"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import LinkifyText from "../shared/LinkifyText"
import RelatedPosts from "./RelatedPosts"
import PremiereHeader from "./PremiereHeader"
import clsx from "clsx"
import styles from "./styles.module.css"

export const LinkListItemExpanded = ({
  post,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(post.status)

  return (
    <div className="grid grid-cols-12 py-1">
      <PremiereHeader post={post} />
      <LinkPreview
        defaultImage={post.external_photo_src || post.cover_photo_url_large}
        unfurledObj={post.link_preview}
        expanded={true}
        onClick={(e) => setIsExpanded(false)}
      />
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex-grow">
          <h2
            className={clsx(
              PostListItemExpandedStyles.header,
              "cursor-pointer inline mr-2",
              post.is_bn_premiere && styles.premiereTitle,
            )}
            onClick={(e) => setIsExpanded(false)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: LinkifyText(post.title),
              }}
            ></span>
          </h2>
          <CopyPostUrlLink nadaUrl={post.nada_url} renderMode="button" />
        </div>
        <Link
          className={PostListItemExpandedStyles.bandName}
          to={post.local_path}
        >
          {post.band_name}
        </Link>
        <h3 className={PostListItemExpandedStyles.postDate}>
          Posted:&nbsp;
          {SmartDateFormat({
            dateStr: post.active_date,
            includeTime: false,
          })}
        </h3>
        <div className={PostListItemExpandedStyles.externalLink}>
          <a href={post.external_url} target="_blank" rel="noreferrer">
            view on&nbsp;
            {post.view_on}
          </a>
        </div>
        <div className="mt-4">
          <RelatedPosts postId={post.id} />
        </div>
        <div className="mt-4">
          <ReactionBlock
            contentType="Post"
            contentId={post.id}
            postId={post.id}
          />
        </div>
        <div className={PostListItemExpandedStyles.description}>
          <div className="font-bold">{post.link_preview.best_title}</div>
          <FreeTextFormat
            text={post.link_preview.description}
            expandable={false}
          />
        </div>
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <CommentScreen
          postId={post.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}
