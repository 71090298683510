import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import CommentScreen from "../comments/CommentScreen"
import { TextAsImage } from "./TextAsImage"
import { PostListItemStyles } from "./constants"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import { UserContext } from "../HomeApp"
import PostEditButton from "../PostEditButton"
import HeadlinePostForm from "../posts/HeadlinePostForm"
import PreviewImages from "./PreviewImages"
import clsx from "clsx"
import FreeTextFormat from "../shared/FreeTextFormat"
import styles from "./styles.module.css"
import PremiereHeader from "./PremiereHeader"

export const HeadlineListItem = ({
  headline,
  setIsExpanded,
  updateStatus,
  adminMode = false,
}) => {
  const [currentHeadline, setCurrentHeadline] = useState(headline)
  const [postStatus, setPostStatus] = useState(headline.status)
  const [showEditForm, setShowEditForm] = useState(false)
  const { user } = useContext(UserContext)

  return showEditForm ? (
    <HeadlinePostForm
      bandId={user.band_id}
      setShowForm={setShowEditForm}
      post={headline}
      setNewPost={setCurrentHeadline}
    />
  ) : (
    <div className="grid grid-cols-12">
      {currentHeadline.attached_media ? (
        <PreviewImages attachedMedia={currentHeadline.attached_media} />
      ) : (
        <TextAsImage
          text={currentHeadline.title}
          onClick={(e) => setIsExpanded(true)}
          className="cursor-pointer"
        />
      )}

      <div className={PostListItemStyles.metaDiv}>
        <PremiereHeader post={currentHeadline} />
        <div className="flex items-start">
          <h2
            className={clsx(
              PostListItemStyles.header,
              currentHeadline.title.length > 100
                ? PostListItemStyles.headerLong
                : PostListItemStyles.headerShort,
              "cursor-pointer",
              currentHeadline.is_bn_premiere && styles.premiereTitle,
            )}
            onClick={(e) => setIsExpanded(true)}
          >
            <FreeTextFormat
              text={
                currentHeadline.title
                  ? currentHeadline.title
                  : `New Headline from ${currentHeadline.band_name}`
              }
              expandable={false}
              expanded={false}
              linkify={true}
            />
          </h2>
          <CopyPostUrlLink
            nadaUrl={currentHeadline.nada_url}
            renderMode="icon"
          />
          <PostEditButton post={headline} setShowEditForm={setShowEditForm} />
        </div>

        <Link
          className={PostListItemStyles.bandName}
          to={currentHeadline.local_path}
        >
          {currentHeadline.band_name}
        </Link>
        <h3 className={PostListItemStyles.postDate}>
          Posted:&nbsp;
          {SmartDateFormat({
            dateStr: currentHeadline.active_date,
            includeTime: false,
          })}
        </h3>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}
        <div className="mt-4">
          <RelatedPosts postId={currentHeadline.id} />
        </div>
        <div className="mt-6">
          <ReactionBlock
            contentType="Post"
            contentId={currentHeadline.id}
            postId={currentHeadline.id}
          />
        </div>
        <CommentScreen postId={currentHeadline.id} />
      </div>
    </div>
  )
}
