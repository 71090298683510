import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../util/dates"
import FreeTextFormat from "../shared/FreeTextFormat"
import CommentScreen from "../comments/CommentScreen"
import { format } from "date-fns"
import { UserContext } from "../HomeApp"
import { TextAsImage } from "./TextAsImage"
import { PostListItemExpandedStyles } from "./constants"
import InterestedBlock from "./InterestedBlock"
import EventForm from "../EventForm"
import { CopyPostUrlLink } from "./CopyPostUrlLink"
import TaggedBandScreen from "../shared/TaggedBandScreen"
import ConcertMediaScreen from "../comments/ConcertMediaScreen"
import GoogleMap from "../shared/GoogleMap"
import Marker from "../shared/Marker"
import { ShowHideButton } from "../shared/Button"
import { ReactionBlock } from "./ReactionBlock"
import RelatedPosts from "./RelatedPosts"
import PostEditButton from "../PostEditButton"
import clsx from "clsx"
import PremiereHeader from "./PremiereHeader"
import styles from "./styles.module.css"

const ConcertListItemExpanded = ({
  concert,
  setIsExpanded,
  setPostListItem,
  updateStatus = () => {},
  adminMode = false,
}) => {
  const [postStatus, setPostStatus] = useState(concert.status)
  const { googlek, user } = useContext(UserContext)
  const isOwner = user && user.band && user.band.id === concert.band_id
  const loadEdit =
    new URLSearchParams(window.location.search).get("edit") === "true"
  const [showEditForm, setShowEditForm] = useState(isOwner && loadEdit)

  const setConcert = (newConcert) => {
    setPostListItem(newConcert)
  }

  const imgStyle = {
    width: "100%",
    aspectRatio: "16 / 9",
  }

  return showEditForm ? (
    <EventForm
      setShowForm={setShowEditForm}
      concert={concert}
      setConcert={setConcert}
    />
  ) : concert.status === "trashed" ? (
    <div className="grid grid-cols-12">
      <div className="col-span-12 font-bold">
        Sorry, this event has been removed. You can check the{" "}
        <Link to={concert.local_path} className="text-blue-600">
          band's page
        </Link>{" "}
        to see if they replaced it with another event.
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-12">
      <PremiereHeader post={concert} />
      <div
        className={
          PostListItemExpandedStyles.contentDiv +
          `${
            concert.cover_image_url_large || !concert.venue_geo[0]
              ? ""
              : " h-64"
          }`
        }
      >
        <>
          {concert.cover_image_url_large ? (
            <img
              src={concert.cover_image_url_large}
              alt={concert.title}
              onClick={(e) => setIsExpanded(false)}
              className="cursor-pointer w-full"
              style={imgStyle}
            />
          ) : concert.event_type !== "bNliveStream" &&
            concert.venue_geo[0] &&
            concert.venue_geo[1] ? (
            <GoogleMap
              bootstrapURLKeys={{
                key: googlek,
              }}
              defaultCenter={{
                lat: concert.venue_geo[0],
                lng: concert.venue_geo[1],
              }}
              defaultZoom={15}
            >
              <Marker
                key={concert.id}
                text={concert.venue_name}
                // @ts-ignore
                lat={concert.venue_geo[0]}
                lng={concert.venue_geo[1]}
              />
            </GoogleMap>
          ) : (
            <button
              onClick={(e) => setIsExpanded(false)}
              className="cursor-ponter w-full"
            >
              <TextAsImage text={concert.title} />
            </button>
          )}
        </>
      </div>
      <div className={PostListItemExpandedStyles.metaDiv}>
        <div className="flex">
          <h2
            className={clsx(
              PostListItemExpandedStyles.header,
              "cursor-pointer inline mr-2",
              concert.is_bn_premiere && styles.premiereTitle,
            )}
            dangerouslySetInnerHTML={{ __html: concert.title }}
            onClick={(e) => setIsExpanded(false)}
          ></h2>
          <CopyPostUrlLink nadaUrl={concert.nada_url} renderMode="button" />
          <PostEditButton post={concert} setShowEditForm={setShowEditForm} />
        </div>

        <h6 className={PostListItemExpandedStyles.secondaryHeader}>
          {concert.event_type === "bNliveStream"
            ? "bandNada Live Stream"
            : "Live In-Person Event"}
          {concert.status === "needs_approved" && isOwner ? (
            <span className="text-red-600 ml-4">
              Hidden Event{" "}
              <button
                className="text-blue-700 ml-2 border-b-2 border-blue-300 focus-outline-none"
                onClick={() => setShowEditForm(true)}
              >
                Publish for your fans now
              </button>
            </span>
          ) : null}
        </h6>

        <InterestedBlock
          postId={concert.id}
          bandId={concert.band_id}
          renderMode="full"
          maxRsvps={concert.max_rsvps}
        />

        <div className={PostListItemExpandedStyles.postDate}>
          {SmartDateFormat({
            dateStr: concert.start_dt,
            includeTime: true,
          })}
          ~{format(new Date(concert.end_dt), "h:mma")}
          &nbsp;
          <a
            href={concert.add_to_calendar_url}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700"
          >
            (+ add to calendar)
          </a>
        </div>
        {concert.post_type === "concert" && (
          <div className={PostListItemExpandedStyles.externalLink}>
            <a href={concert.external_url} target="_blank">
              view on&nbsp;
              {concert.class_name}
            </a>
          </div>
        )}

        <h6 className={PostListItemExpandedStyles.secondaryDescriptor}>
          Hosted By:
        </h6>
        <Link
          className={PostListItemExpandedStyles.bandName}
          to={concert.local_path}
        >
          {concert.band_name}
        </Link>
        {concert.event_type === "bNliveStream" ? (
          <div className="text-sm">
            <a href={concert.band_url} className="text-gray-700">
              {concert.band_url}
            </a>
          </div>
        ) : concert.venue_name || concert.venue_city_display_name ? (
          <div className="text-sm">
            <a
              className="text-gray-700"
              href={concert.google_map_url}
              target="_blank"
              rel="noreferrer"
            >
              {concert.venue_name && concert.venue_city_display_name
                ? `${concert.venue_name} in ${concert.venue_city_display_name}`
                : `${concert.venue_name}${concert.venue_city_display_name}`}
            </a>
          </div>
        ) : null}

        {concert.ticket_link_url ? (
          <div className="mt-2">
            <a
              href={concert.ticket_link_url}
              target="_blank"
              className="border-b-2 border-blue-300 pt-1 text-blue-600 text-sm"
            >
              Ticket Link
            </a>
          </div>
        ) : null}

        <div className="mt-4">
          <ReactionBlock
            contentType="Post"
            contentId={concert.id}
            postId={concert.id}
          />
        </div>

        {concert.description && (
          <div className={PostListItemExpandedStyles.description}>
            <FreeTextFormat
              text={concert.description}
              expandable={false}
              expanded={true}
              linkify={true}
            />
          </div>
        )}

        <div className="text-gray-700 mt-2">
          {concert.performers_ref &&
            concert.performers_ref.map((performer_ref, i) => (
              <span key={performer_ref[0]}>
                {/^http/.test(performer_ref[1]) ? (
                  <a
                    className="block"
                    href={performer_ref[1]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {performer_ref[0]}
                  </a>
                ) : (
                  <Link
                    className="block font-bold text-green-700"
                    to={performer_ref[1]}
                  >
                    {performer_ref[0]}
                  </Link>
                )}
              </span>
            ))}
        </div>
        <RelatedPosts postId={concert.id} />
        <TaggedBandScreen postId={concert.id} />
        <ConcertMediaScreen postId={concert.id} />
      </div>
      <div className={PostListItemExpandedStyles.commentsDiv}>
        {adminMode && (
          <ShowHideButton
            updateStatus={updateStatus}
            postStatus={postStatus}
            setPostStatus={setPostStatus}
          />
        )}

        <CommentScreen
          postId={concert.id}
          headerText="The Peanut Gallery Says:"
          className="mb-4"
        />
      </div>
    </div>
  )
}

export default ConcertListItemExpanded
